.App {
    font-family: sans-serif;
    text-align: center;
    height: 50vh;
}

.App-map {
    height: 100%;
    width: 100%;
}
